import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { isFilePath } from '../../functions/common';

// From Gatby's example:
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#use-link-only-for-internal-links

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName = '', partiallyActive = false, ...other }) => {
  // This assumes all internal links start with exactly one slash,
  // and that anything else is external.
  const internal = /^\/(?!\/)/.test(to),
    linkToFile = isFilePath(to);

  // Use Gatsby Link for internal links, and <a> for others
  return internal && !linkToFile ? (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...other}
    >
      {children}
    </GatsbyLink>
  ) : (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

export default Link;
