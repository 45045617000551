import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { createMenuTree } from './build-menu-tree';
import Link from '../../components/elements/link';
import { replaceSpecialCharacters, isFilePath } from '../common';
import { useStaticQuery, graphql } from 'gatsby';

const park = process.env.GATSBY_PARK;
const menuId = park + '-top-nav';
const menuMaxLevel = 3;

function toggleNavItemChildren(event) {
  const navButton = event.currentTarget;
  navButton?.classList.toggle('navigation-dropdown__anchor--active');
  navButton?.nextElementSibling.classList.toggle('navigation-dropdown__panel--active');
}

function hasChildren(menuItem) {
  try {
    return menuItem.children?.length !== 0 ? true : false;
  } catch (e) {
    return false;
  }
}

//*  Define HTML of the individual links
const buildLink = (menuItem) => {
  let menuLink;
  // Cater for Drupal menu links with no url alias e.g. manually added menu items
  let linkUrl = menuItem.link.uri_alias || menuItem.link.uri;

  // Top level menu items
  if (menuItem.menu_level === 1) {

    menuLink = (
      <Link
        to={linkUrl}
        className="navigation-dropdown__anchor navigation-dropdown__anchor--top-level"
        id={replaceSpecialCharacters(`nav-${menuItem.title.toLowerCase()}`)}
      >
        <span>{menuItem.title}</span>
      </Link>
    );
  } else if (menuItem.menu_level === 2 && isFilePath(menuItem.description)) {
    // Show Menu images if the 'Description' field is populated
    // and restrict this layout to level 2 menu items only

    let imgData =
      getImage(menuItem.customMediaMenuImage?.customLocalFieldMediaImage2) ||
      menuItem.customMediaMenuImage?.customLocalFieldMediaImage2?.publicURL ||
      null;

    menuLink = (
      <Link to={linkUrl} className="feature-box">
        <div className="feature-box__inner">
          {imgData ? (
            <GatsbyImage
              image={imgData}
              alt={menuItem.title}
              className="feature-box__image"
              loading="eager"
            />
          ) : (
            <React.Fragment key={menuItem.id}></React.Fragment>
          )}
          <div className="feature-box__text-overlay feature-box__text-overlay--bottom-left">
            <span>{menuItem.title}</span>
          </div>
        </div>
      </Link>
    );
  } else {
    // 3rd level items, and 2nd level if no valid image path is found
    menuLink = <Link to={linkUrl}>{menuItem.title}</Link>;
  }
  return menuLink;
};

// Define the HTML structure of the menu item wrappers down through all levels
const buildMenu = (menuArray) => {
  if (!menuArray) {
    return;
  }

  let menuShell = [];

  for (let item in menuArray) {
    let menuItem = menuArray[item];
    // Hide menu items below a certain level
    if (menuItem.menu_level > menuMaxLevel || menuItem.enabled !== true) {
      // Ignore these items and continue to the next
      continue;
    }

    // Top level menu items
    if (menuItem.menu_level === 1) {
      let linkUrl = menuItem.link.uri_alias || menuItem.link.uri;
      menuShell.push(
        <li className="navigation-dropdown__item" key={menuItem.drupal_id}>
          {buildLink(menuItem)}
          {hasChildren(menuItem) ? (
            <React.Fragment key={menuItem.id}>
              <button
                onClick={toggleNavItemChildren}
                className="navigation-dropdown__anchor navigation-dropdown__anchor--button"
              >
                <span>{menuItem.title}</span>
              </button>
              <div
                aria-labelledby={replaceSpecialCharacters(`nav-${menuItem.title.toLowerCase()}`)}
                className="navigation-dropdown__panel"
              >
                <ul className="navigation-dropdown__panel-inner navigation-dropdown__panel-list">
                  {/* Duplicate the top level menu item for mobiles, as the real one acts as the
                  button to expose the child menu and can't be clicked as a link */}
                  <li
                    className="devices-only navigation-dropdown__panel-list-item"
                    key={menuItem.drupal_id}
                  >
                    <Link to={linkUrl}>{menuItem.title}</Link>
                  </li>
                  {buildMenu(menuItem.children)}
                </ul>
              </div>
            </React.Fragment>
          ) : (
            <Link
              to={linkUrl}
              className="navigation-dropdown__anchor navigation-dropdown__anchor--button"
            >
              {menuItem.title}
            </Link>
          )}
        </li>
      );
    } else if (menuItem.menu_level === 2) {
      // Child menu items
      // Level 2 menu items show description images and child menu items
      menuShell.push(
        <li className="navigation-dropdown__panel-column" key={menuItem.drupal_id}>
          <div className="navigation-dropdown__panel-column-inner">
            {buildLink(menuItem)}
            {hasChildren(menuItem) ? (
              <ul className="navigation-dropdown__panel-list">{buildMenu(menuItem.children)}</ul>
            ) : (
              <React.Fragment key={menuItem.id}></React.Fragment>
            )}
          </div>
        </li>
      );
    } else {
      // Level 3+ menu items, noting only up to L3 is displayed
      // This is the standard menu item, including anything level 2
      // with no children or image path in the description field
      menuShell.push(
        <li className="navigation-dropdown__panel-list-item" key={menuItem.drupal_id}>
          {buildLink(menuItem)}
          {/* Not required at this stage, as we only go down to Level 3 */}
          {/* {buildMenu(menuItem.children)} */}
        </li>
      );
    }
  }

  return menuShell;
};

const generateMenu = (menuLinks) => {
  let menu = createMenuTree(menuLinks.allMenuItem.edges, menuId);
  const builtMenu = buildMenu(menu);
  return builtMenu;
};

export default function MainMenu() {
  // This query deliberately returns all items for all menus,
  // which are then filtered above. We can't dynamically filter
  // by park or menu name as static queries don't accept variables.
  // Filtering is done in createMenuTree();
  const data = useStaticQuery(graphql`
    {
      allMenuItem(sort: { fields: weight }) {
        edges {
          node {
            enabled
            title
            menu_name
            customMediaMenuImage {
              ...MediaDataMenuImage
            }
            link {
              uri
              uri_alias
            }
            description
            drupal_id
            bundle
            drupal_parent_menu_item
            weight
            external
          }
        }
      }
    }
  `);
  return data ? (
    <ul id="main-navigation" className="navigation-dropdown navigation-dropdown--main clearfix">
      {generateMenu(data)}
    </ul>
  ) : (
    <React.Fragment key={'no-main-navigation-loaded'}></React.Fragment>
  );
}
