//* This file is a basic reverse engineer of a minified file from Cloudcannon:
//  https://github.com/envris/booderee/blob/e4b2d3ee4858fa1c2730f3431ef31c661eea5ef0/_includes/shared/head/inline-header-js.html
//  The source version is nowhere to be found.
//  It handles adding classes to the html element based on media queries and font loading.

export default !(function (global) {
  function loadModule(moduleId) {
    if (moduleCache[moduleId]) {
      return moduleCache[moduleId].exports;
    }

    let module = {
      exports: {},
      id: moduleId,
      loaded: false,
    };

    moduleCache[moduleId] = module;
    return (
      global[moduleId].call(module.exports, module, module.exports, loadModule),
      (module.loaded = true),
      module.exports
    );
  }

  let moduleCache = {};

  loadModule(0);
})({
  0: function (module, exports, loadModule) {
    loadModule(44);
  },
  36: function (module, exports) {
    (function (global, document) {
      'use strict';

      var metaQuery = {
        breakpoints: {},
        _isTicking: false,
        _debounceLastTime: 0,
        _namedEvents: {},
        _eventMatchCache: {},
        _globalEvents: [],

        onBreakpointChange: function () {
          let args = Array.prototype.slice.call(arguments),
            callback = args.pop(),
            breakpointName = args.pop();

          if (typeof breakpointName === 'undefined') {
            // @ts-ignore
            metaQuery._globalEvents.push(callback);
          } else {
            metaQuery._namedEvents[breakpointName] = [];
            metaQuery._namedEvents[breakpointName].push(callback);
          }

          updateViewport();
        },
      };

      function waitForReadyState(callback) {
        if (/in/.test(document.readyState)) {
          setTimeout(function () {
            waitForReadyState(callback);
          }, 9);
        } else {
          callback();
        }
      }

      function removeClass(element, className) {
        let classes = element.className.split(' ');
        let index = classes.indexOf(className);
        if (index > -1) {
          classes.splice(index, 1);
          element.className = classes.join(' ');
        }
      }

      function addClass(element, className) {
        if (element.className.indexOf(className) === -1) {
          element.className =
            element.className !== '' ? element.className + ' ' + className : className;
        }
      }

      function updateViewportClass(isMatch, breakpointName) {
        let className = 'breakpoint-' + breakpointName;
        let htmlElement = document.documentElement;

        isMatch ? addClass(htmlElement, className) : removeClass(htmlElement, className);
      }

      function triggerGlobalEvents(matchedBreakpoints) {
        metaQuery._globalEvents.forEach(function (eventCallback) {
          if (typeof eventCallback === 'function') {
            // @ts-ignore
            eventCallback(matchedBreakpoints);
          }
        });
      }

      function updateViewport() {
        metaQuery._isTicking || requestAnimationFrame(debounceUpdate);
        metaQuery._isTicking = true;
      }

      function debounceUpdate() {
        metaQuery._isTicking = false;
        let matchedBreakpoints = [];

        for (let breakpointName in metaQuery.breakpoints) {
          let mediaQuery = metaQuery.breakpoints[breakpointName];
          let isMatch = matchMedia(mediaQuery).matches;

          if (
            metaQuery._namedEvents[breakpointName] &&
            metaQuery._eventMatchCache[breakpointName] !== isMatch
          ) {
            metaQuery._eventMatchCache[breakpointName] = isMatch;

            metaQuery._namedEvents[breakpointName].forEach(function (eventCallback) {
              if (typeof eventCallback === 'function') {
                eventCallback(isMatch);
              }
            });
          }

          isMatch && matchedBreakpoints.push(breakpointName);
          updateViewportClass(isMatch, breakpointName);
        }

        if (matchedBreakpoints.length > 0) {
          triggerGlobalEvents(matchedBreakpoints);
        }
      }

      function parseMetaTags() {
        let metaTags = document.getElementsByTagName('meta');

        for (let i = 0; i < metaTags.length; i++) {
          if (metaTags[i].name === 'breakpoint') {
            let breakpointName = metaTags[i].getAttribute('content');
            let mediaQuery = metaTags[i].getAttribute('data-breakpoint');
            metaQuery.breakpoints[breakpointName] = mediaQuery;
          }
        }
      }

      function initialize() {
        parseMetaTags();
        updateViewport();
      }

      function initializeAndListen() {
        parseMetaTags();
        updateViewport();
        global.addEventListener('resize', updateViewport);
      }

      // @ts-ignore
      global.metaQuery = metaQuery;

      if (typeof exports !== 'undefined') {
        exports = metaQuery;
      }

      initialize();
      waitForReadyState(initializeAndListen);
    })(window, document);
  },
  42: function (module, exports) {
    function checkFontLoading() {
      if (window.localStorage.getItem('fontsLoaded')) {
        document.documentElement.className += ' fonts-loaded';
      }
    }

    module.exports = checkFontLoading;
  },
  43: function (module, exports, loadModule) {
    loadModule(36);
    let checkFontLoading = loadModule(42);
    checkFontLoading();
  },
  44: function (module, exports, loadModule) {
    loadModule(43);
  },
});
