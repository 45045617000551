import React from 'react';
import { Helmet } from 'react-helmet';
import { getParkNames } from '../../functions/common';

const park = process.env.GATSBY_PARK;
export default function HtmlHead() {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        class: park,
      }}
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="breakpoint" content="phone" data-breakpoint="(max-width: 639px)" />
      <meta name="breakpoint" content="phone-portrait" data-breakpoint="(max-width: 479px)"></meta>
      <meta
        name="breakpoint"
        content="tablet"
        data-breakpoint="(min-width: 640px) and (max-width: 767px)"
      />
      <meta
        name="breakpoint"
        content="tablet-wide"
        data-breakpoint="(min-width: 768px) and (max-width: 1023px)"
      />
      <meta
        name="breakpoint"
        content="desktop"
        data-breakpoint="(min-width: 1024px) and (max-width: 1399px)"
      />
      <meta name="breakpoint" content="widescreen" data-breakpoint="(min-width: 1400px)" />
      <meta name="breakpoint" content="w-1080" data-breakpoint="(max-width: 1080px)" />
      <meta name="breakpoint" content="w-1140" data-breakpoint="(max-width: 1140px)" />
      <meta name="breakpoint" content="w-1230" data-breakpoint="(max-width: 1230px)" />
      <meta name="breakpoint" content="w-1366" data-breakpoint="(max-width: 1366px)" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Parks_Australia" />
      <meta property="fb:admins" content="509080352501129" />
    </Helmet>
  );
}

export function PageMetadata({ title, description, thumbnailImage, path, dateModified }) {
  const domain = 'https://' + getParkNames().domain,
    url = domain + path,
    imageUrl = thumbnailImage ? domain + thumbnailImage : '',
    seoTitle = path === '/' ? `${getParkNames().fullName} | Parks Australia` : `${title} | ${getParkNames().fullName} | Parks Australia`,
    seoLinkedData = JSON.stringify({
      dateModified: dateModified,
      description: description,
      image: imageUrl,
      '@type': 'WebPage',
      url: url,
      headline: seoTitle,
      '@context': 'http://schema.org',
    });

  return (
    <Helmet defaultTitle={`${getParkNames().fullName} | Parks Australia`} title={seoTitle}>
      <link rel="canonical" href={url} />
      <meta property="og:title" content={seoTitle} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <script type="application/ld+json">{seoLinkedData}</script>
    </Helmet>
  );
}
