// Logic for rebuilding Drupal menus for Gatsby
import {
  stripUrlEntityPrefix,
  stripUrlParkPrefix,
  isInputDataFormatCorrect,
  getParkNames,
  convertTrueFalseStringToBoolean,
  testEnv,
} from '../common';

const env = testEnv();

//* Generate the menu trail of the current page back to the top level of the menu
// Used for creating breadcrumb trails
//   - allMenuData: an object containing the menu items
//   - nodeID: the Drupal ID of the current page, as a number
//   - url: the URL of the current page, as a string
//   - nodeType: optional, the Drupal bundle of the current page, as a string
//   - nodeTitle: optional, the title of the current page, as a string. This can be
//                useful if you want to expose the full title rather thana  short
//                version that may be used in the menu

export const createPageParentTrail = (allMenuData, nodeID, url, nodeType, nodeTitle) => {
  if (!allMenuData || !nodeID) {
    console.error(
      `[ERROR - Menus]: No menu data or Node ID provided to createPageParentTrail(), quitting...`
    );
    return;
  } else if (
    !isInputDataFormatCorrect(nodeID, 'number', 'createPageParentTrail') ||
    !isInputDataFormatCorrect(allMenuData, 'object', 'createPageParentTrail')
  ) {
    return;
  }
  if (typeof url !== undefined) {
    if (!isInputDataFormatCorrect(url, 'string', 'createPageParentTrail')) {
      return;
    }
  }

  // Find the current page in the menu data and set a new object to
  // hold the parent structure
  let parentLevel = 0,
    parentMenuTrail = [],
    parkHomeLink = getParkNames().fullName;

  // For page-like content with no menu positions (e.g. news articles),
  // we hardcode the menu trail
  if (
    nodeType !== undefined &&
    nodeType === 'news_article' &&
    nodeTitle !== undefined &&
    typeof nodeTitle === 'string'
  ) {
    // Add the current page
    parentMenuTrail.push({
      crumbLevel: parentLevel,
      node: {
        title: nodeTitle,
        link: {
          uri_alias: url,
        },
      },
    });
    // Add a hard link to the parent page
    parentMenuTrail.push({
      crumbLevel: parentLevel + 1,
      node: {
        title: 'News',
        link: {
          uri_alias: '/news/',
        },
      },
    });
  } else {
    // For all other pages with menu positions, loop through the menu data

    for (let i = 0; i < allMenuData.length; i++) {
      // Prioritise URL aliases, then fall back to the URI for external links
      const thisPagePath =
        stripUrlParkPrefix(stripUrlEntityPrefix(allMenuData[i].node.link?.uri_alias)) ||
        stripUrlParkPrefix(stripUrlEntityPrefix(allMenuData[i].node.link.uri)) ||
        null;
      let parentMenuItemId;

      if (!thisPagePath) {
        console.warn(
          `[ISSUE - Menus]: No URL alias found for menu item '${allMenuData[i].node.title}', so it will not be added to the breadcrumbs`
        );
      } else if (thisPagePath !== null && thisPagePath === url) {
        // Start tracking the parent levels in the menu hierarchy, noting
        // we zero-index the count, and go from 0+. We don't assume how
        // many parents an item may have. Life can be complicated and that's ok :)
        allMenuData[i].crumbLevel = parentLevel;
        parentMenuTrail.push(allMenuData[i]);
        parentLevel++;

        // Handle top level items that have no parents, like Batman. He's an Apex Item, if anything
        allMenuData[i].node.drupal_parent_menu_item !== null
          ? (parentMenuItemId = allMenuData[i].node.drupal_parent_menu_item.split(':')[1])
          : (parentMenuItemId = null);

        // Start searching for the parents of the current page
        findParents(allMenuData, parentMenuItemId);
        break;
      }
    }

    // Search each parent to build up the menu trail back to the top level
    function findParents(menuData, id) {
      menuData.forEach((item) => {
        if (item.node.drupal_id === id) {
          item.crumbLevel = parentLevel;
          // If the parent is found AND enabled, add it to the parentMenuTrail object
          // This allows breadcrumbs to function correctly when a page is nested
          // under a disabled menu item
          if (item.node.enabled) {
            parentMenuTrail.push(item);
          }
          // If this parent item also has a parent, keep searching up the tree
          if (item.node.drupal_parent_menu_item !== null) {
            parentLevel++;
            findParents(allMenuData, item.node.drupal_parent_menu_item.split(':')[1]);
          }
        }
      });
    }
  }
  // Add the home link manually - simpler than retrieving and tweaking the data
  parentMenuTrail.push({
    crumbLevel: parentMenuTrail.length,
    node: {
      title: parkHomeLink,
      link: {
        uri_alias: '/',
      },
    },
  });
  return parentMenuTrail;
};

// Rebuild the menu structure, as Drupal's JSON API doesn't return
// a nested menu structure: (
//    allMenuData: an object containing the menu items e.g. menuLinks.allMenuItem.edges
//    menuId: the machine name of the menu to be rebuilt, as a string

export const createMenuTree = (allMenuData, menuId) => {
  let menuTree = [],
    mappedArr = {};

  // First map the nodes of the array to an object -> create a hash table.

  if (!allMenuData || !menuId) {
    console.error('[ERROR - Menus]: Missing arguments for createMenuTree()');
    return;
  } else if (typeof allMenuData !== 'object') {
    console.error(
      "[ERROR - Menus]: Invalid data type for 'allMenuData' in `createMenuTree()`, expected an object"
    );
    return;
  } else if (typeof menuId !== 'string') {
    console.error(
      '[ERROR - Menus]: Invalid data type for menuId in `createMenuTree()`, expected a string'
    );
    return;
  }

  const createMenuTreeBranch = () => {
    let menuItems = [];

    // Collect all menu items only from the target menu - enabled or disabled
    // This is because if a page happens to live under a disabled parent, we
    // still want that page to have access to the correct menu tree to build a
    // breadcrumb trail that skips the disabled parent.
    for (const item in allMenuData) {
      if (allMenuData[item].node.menu_name === menuId) {
        // --- Any operations to be run over all links go here ---

        allMenuData[item].node.link.uri = stripUrlParkPrefix(
          stripUrlEntityPrefix(allMenuData[item].node.link.uri)
        );
        allMenuData[item].node.link.uri_alias = stripUrlParkPrefix(
          stripUrlEntityPrefix(allMenuData[item].node.link.uri_alias)
        );
        menuItems.push(allMenuData[item]);
      }
    }
    return menuItems;
  };

  const thisMenuBranch = createMenuTreeBranch();

  // Map each menu item to an array, then check if it has child items
  for (let i = 0, len = thisMenuBranch.length; i < len; i++) {
    let arrElem = thisMenuBranch[i].node;
    mappedArr[arrElem.drupal_id] = arrElem;

    // If an item has a parent, unlike Bruce Wayne, strip the bundle prefix from the ID
    // and add it to the mapped Array
    if (arrElem.drupal_parent_menu_item != null) {
      let stripped_drupal_id = arrElem.drupal_parent_menu_item.replace(arrElem.bundle + ':', '');
      mappedArr[arrElem.drupal_id].drupal_parent_menu_item = stripped_drupal_id;
    }
    // Set the 'children' key to be an empty array so we can populate it later as children are discovered
    mappedArr[arrElem.drupal_id]['children'] = [];
  }

  for (const item in mappedArr) {
    if (mappedArr.hasOwnProperty(item)) {
      let mappedElem = mappedArr[item];

      // If the element is not at the root level and has a parent,
      // add it to its parent's array of children.
      if (mappedElem.drupal_parent_menu_item !== null) {
        if (mappedArr[mappedElem.drupal_parent_menu_item] !== undefined) {
          mappedArr[mappedElem.drupal_parent_menu_item]['children'].push(mappedElem);
        }
        // If the parent menu item is set but does not exist in mappedArr, the item
        // lives under a disabled parent menu item and should be excluded entirely.
      } else {
        // If the element is at the root level, add it to first level elements array.
        menuTree.push(mappedElem);
      }
    }
  }

  // Iterate over the final menu structure to assign menu levels.
  // It would be easier if Drupal's JSON API did this before spitting out the links...
  function assignMenuLevels(menu, menu_level = 1) {
    menu.forEach((obj) => {
      obj.menu_level = menu_level;
      assignMenuLevels(obj.children, menu_level + 1);
    });
  }

  assignMenuLevels(menuTree);

  //* Render out the menu trees used on a page to the console for debugging
  if (env.devMode && convertTrueFalseStringToBoolean(process.env.DEBUG_MENUS) === true) {
    let menuChart = '';

    function crawlTree(menuObj) {
      for (const item in menuObj) {
        logMenu(menuObj[item]);
        if (menuObj[item].children.length > 0) {
          crawlTree(menuObj[item].children);
        }
      }
    }

    function logMenu(item) {
      let gap = '';
      for (let i = 0; i < parseInt(item.menu_level); i++) {
        gap += '  ';
      }
      menuChart += `${gap} Lv${item.menu_level} - ${item.title}\n`;
    }

    console.log(`Menu: ${menuTree[0].menu_name}`);
    crawlTree(menuTree);
    console.log(menuChart);
  }

  return menuTree;
};
