import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isDummyOrTestContent, showTestContent } from '../../functions/common';

export default function FooterLogosBlock({ park }) {
  const data = useStaticQuery(graphql`
    query {
      allBlockContentFooterLogosPanel {
        nodes {
          info
          field_site_for_block {
            drupal_internal__target_id
          }
          body {
            processed
          }
        }
      }
    }
  `);
  const result = data.allBlockContentFooterLogosPanel.nodes.map((item) => {
    if (
      item.field_site_for_block?.drupal_internal__target_id === park &&
      (!isDummyOrTestContent(item.info) || isDummyOrTestContent(item.info && showTestContent))
    ) {
      return (
        <div
          className="grid-col grid-col--12 block_footer_logos"
          key={item.body?.processed}
          dangerouslySetInnerHTML={{ __html: item.body?.processed }}
        />
      );
    }
  });
  return <div className="footer__nav-wrapper clearfix">{result}</div>;
}
