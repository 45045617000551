import React, { memo } from 'react';
import Link from './link'

const SkipLinks = memo(() => {
  return (
    <div id="skip-links" className="skip-links-wrapper">
      <Link to="#main-navigation" className="skip-link hide-visually focusable ">
        Skip to main navigation
      </Link>
      <Link to="#content" className="skip-link hide-visually focusable ">
        Skip to main content
      </Link>
      <Link to="#search" className="skip-link hide-visually focusable">
        Skip to search
      </Link>
      <Link to="#share" className="skip-link hide-visually focusable">
        Skip to share on social media
      </Link>
    </div>
  );
});

export default SkipLinks;
