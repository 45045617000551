import React, { memo } from 'react';
import FooterLogosBlock from '../blocks/footer-logos';
const park = process.env.GATSBY_PARK;

const FooterLogosPanel = memo(() => {
  return (
    <div className="footer__logos-panel">
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <FooterLogosBlock park={park} />
        </div>
      </div>
    </div>
  );
});

export default FooterLogosPanel;
