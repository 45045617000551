/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

function closeMenu() {
  let qs = document.querySelector(
    '.navigation-dropdown__item > button.navigation-dropdown__anchor.mobile-nav-active'
  );
  if (qs) {
    let openChildren = document.querySelectorAll(
      '.navigation-dropdown__item > button.navigation-dropdown__anchor.navigation-dropdown__anchor--active'
    );
    // Prevent menu reopening with previously exposed items
    openChildren.forEach((child) => {
      child.classList.remove('navigation-dropdown__anchor--active');
      child.nextElementSibling.classList.remove('navigation-dropdown__panel--active');
    });
    qs.click();
  }
}

exports.onInitialClientRender = () => {
  const assessBreakpoints = require('./breakpoint-events.js');

  // console.log("ReactDOM.render has executed")
  window.dispatchEvent(new Event('resize'));
  document.addEventListener('keyup', function (event) {
    if (event.key === 'Escape') {
      // console.log('escape pressed')
      closeMenu();
    }
  });
  document.body.addEventListener(
    'click',
    function (x) {
      let p = [...x.target.parentNode.classList].concat(Array.from(x.target.classList));
      if (p.includes('navigation-dropdown__anchor') || p.includes('navigation-dropdown__item')) {
        return;
      }
      closeMenu();
    },
    true
  );
};
exports.onRouteUpdate = ({ location, prevLocation }) => {
  closeMenu();
  window.dispatchEvent(new Event('resize'));
};
exports.onClientEntry = () => {
  // console.log("------ onClientEntry")
  // callAnalyticsAPI()
};
