// @ts-nocheck
import React, { memo } from 'react';
import FooterMenu from '../../functions/build_menus/menu-footer';
import FooterNavListBlock from '../blocks/footer-nav-list';

const park = process.env.GATSBY_PARK;

const FooterNavigationPanel = memo(() => {
  return (
    <div className="footer__navigation-panel">
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--12">
            <div className="footer__nav-wrapper clearfix">
              <FooterMenu />
              <FooterNavListBlock park={park} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FooterNavigationPanel;
