import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isDummyOrTestContent, showTestContent, testEnv } from '../../functions/common.js';
import Link from '../elements/link';

export default function FooterNavListBlock({ park }) {
  const data = useStaticQuery(graphql`
    query {
      allBlockContentFooterNavList {
        nodes {
          id
          drupal_id
          info
          field_site_for_block {
            drupal_internal__target_id
          }
          relationships {
            field_footer_social_links {
              field_social_media_link_url {
                title
                uri
              }
              relationships {
                field_social_media_svg_icon {
                  ...MediaDataSvgIcon
                }
              }
            }
            field_footer_nav_items {
              field_social_media_link_url {
                title
                uri
              }
              relationships {
                field_social_media_svg_icon {
                  ...MediaDataSvgIcon
                }
              }
            }
          }
        }
      }
    }
  `);

  const result = data.allBlockContentFooterNavList.nodes.map((item, index: Number) => {
    if (
      item?.field_site_for_block?.drupal_internal__target_id === park &&
      (!isDummyOrTestContent(item.info) || isDummyOrTestContent(item.info) && showTestContent)
    ) {
      const socialLinksArr = item?.relationships?.field_footer_social_links
          ? item.relationships.field_footer_social_links
          : [],
        appStoreLinksArr = item?.relationships?.field_footer_nav_items
          ? item.relationships.field_footer_nav_items
          : [];

      const socialLinks = socialLinksArr.map((socialLink, index: Number) => {
        const image =
            socialLink.relationships?.field_social_media_svg_icon?.customLocalFieldMediaImage1
              ?.publicURL || null,
          imageAlt =
            socialLink.relationships?.field_social_media_svg_icon?.field_media_image_1?.alt || '';

        // Prevent build errors if attempting to render an unpublished image
        if (!image) {
          testEnv().devMode
            ? console.warn(
                `[ISSUE - Images]: No image found in Footer Nav List block ID ${item.drupal_id}.\nThis can mean you\'re trying to render an unpublished media item, or the source image does not have a matching File node in GrahpQL\n`
              )
            : null;
        }

        return (
          <li className="footer__social-link" key={socialLink.field_social_media_link_url?.uri}>
            <Link
              className="share-icon share-icon--greyscale"
              to={socialLink.field_social_media_link_url.uri}
              title={`Follow us on ${imageAlt}`}
            >
              {image ? (
                <img src={image} alt={imageAlt} loading="eager" />
              ) : (
                <React.Fragment key={socialLink.id}></React.Fragment>
              )}
            </Link>
          </li>
        );
      });

      const appStoreLinksList = appStoreLinksArr.map((appStoreItem, index: Number) => {
        // Includes App Store links, saved under 'Footer Social and App links' custom Block type
        const image =
            appStoreItem.relationships?.field_social_media_svg_icon?.customLocalFieldMediaImage1
              ?.publicURL || null,
          imageAlt =
            appStoreItem.relationships?.field_social_media_svg_icon?.field_media_image_1?.alt || '';

        // Prevent build errors if attempting to render an unpublished image
        if (!image) {
          testEnv().devMode
            ? console.warn(
                `[ISSUE - Images]: No image found in Footer Nav List block ID ${item.drupal_id}.\nThis can mean you\'re trying to render an unpublished media item, or the source image does not have a matching File node in GrahpQL\n`
              )
            : null;
        }
        return image ? (
          <li className="footer__appstore-link" key={appStoreItem.field_social_media_link_url.uri}>
            <Link className="mt-medium" to={appStoreItem.field_social_media_link_url?.uri}>
              <img src={image} alt={imageAlt} />
            </Link>
          </li>
        ) : (
          <React.Fragment key={appStoreItem.field_social_media_link_url?.uri}></React.Fragment>
        );
      });
      return socialLinks.length > 0 || appStoreLinksList.length > 0 ? (
        <ul className="footer__nav-list block_footer_nav_list" key={`footer_nav_list-${item.id}`}>
          {socialLinks.length > 0 && (
            <li className="footer__nav-list-item" key={`social-links-${index.toString()}`}>
              <ul className="footer__social-links">{socialLinks}</ul>
            </li>
          )}
          {appStoreLinksList.length > 0 && (
            <li className="footer__nav-list-item" key={`appstore-links-${index.toString()}`}>
              <ul className="footer__appstore-links">{appStoreLinksList}</ul>
            </li>
          )}
        </ul>
      ) : (
        <React.Fragment key={`footer_nav_list-${item.id}-empty`}></React.Fragment>
      );
    }
  });
  return result;
}
