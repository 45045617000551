// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
const React = require('react');
const { StrictMode } = require('react');

// Adds React's Strict mode for debugging in non-PROD builds:
// www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
// This is also present in gatsby-ssr.js if SSR is being used
exports.wrapRootElement = ({ element }) => {
  return <StrictMode>{element}</StrictMode>;
};
