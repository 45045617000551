// This file provides useful reusable functions to pull into components are required

// Imports
import { useState, useEffect } from 'react';
import parkDetails from '../../_park_details.json';
import moment from 'moment';
import tz from 'moment-timezone';

const park = process.env.GATSBY_PARK,
  parkList = parkDetails[0];

export const showTestContent = process.env.INCLUDE_TEST_CONTENT === 'true';

//* Faster logging for development, use with:
// import {log as l} from '../functions/common';
// then l(msg);
export const log = (message) => {
  if (message === null) {
    return;
  }
  console.log(message);
};

// A quicker console.dir()
export const ld = (data, depth = null) => {
  console.dir(data, { depth: depth });
};

//* Test input types and handle errors
// NOTE: Doesn't work comparing NaN to NaN
// Must also be used below any conditional React Hooks e.g. const isSsr = useIsSsr();
//
//   input: the data to test
//   expectedInputType: the expected data type, as a string
//   funcName: optional, the name of the function calling this as a string, for easier debugging
//
//   Usage:
//     isInputDataFormatCorrect(str, 'string') --> returns true
//     isInputDataFormatCorrect(str, 'object') --> returns false
//
export const isInputDataFormatCorrect = (input, expectedInputType, funcName) => {
  let message = `Expected a '${expectedInputType}' value, '${typeof input}' found instead${
    funcName !== undefined ? `in ${funcName}.` : '.'
  }.`;
  if (typeof input !== expectedInputType) {
    console.warn(`[ISSUE - Scripts]: ${message}`);
    return false;
  } else {
    return true;
  }
};

//* React Hooks

//* Check if a value exists in an object's keys
export const isStringInObjectKeys = (obj, str) => {
  return obj[str] !== undefined;
};

//* Check if value appears in an object's values
export const isStringInObjectValues = (obj, str) => {
  let match = false;
  for (let key in obj) {
    obj[key] === str ? (match = true) : (match = false);
    if (match === true) {
      return match;
    }
  }
  return match;
};

//* Delete the first instance of a target item from an object or array, if it exists
export const deleteItemFromArray = (arr, itemToDelete) => {
  const index = arr.indexOf(itemToDelete);
  if (index !== -1) {
    arr.splice(index, 1);
  }
  return arr;
};

//* Detect Server-side Rendering (SSR)
// Credit to Stephen Cook - https://stephencook.dev/blog/using-window-in-react-ssr/
export const useIsSsr = () => {
  // We always start in "SSR mode", to ensure our initial browser render matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};

//* Insert <script> tags into the <head> of a page in the browser only
// Normally we'd use the <Script> Gatsby component, but we're stuck on v3, it's in 5+
// Extended idea from  Alex McMillan - https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx#answer-34425083

//* Source an external script:
export const useScriptFromPath = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    if (url.includes('/')) {
      script.src = url;
      script.async = true;

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    } else {
      console.error('[ERROR - Scripts]: Attempting to run useScriptFromPath() with non-path URL!');
    }
  }, [url]);
};

//* Insert a coded script:
export const useScriptFromCode = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = url;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url]);
};

//* React Functional Components

//* Returns raw text with all HTML tags stripped, and start/end whitespace trimmed
export const RawText = (inputString) => {
  const isSsr = useIsSsr();
  if (isSsr) return inputString;

  const wrapper = document.createElement('div');
  wrapper.innerHTML = inputString;
  const result = wrapper.textContent || wrapper.innerText || '';
  return result.trimStart().trimEnd();
};

//* Replace all non A-Z characters in a string with a dash, handy for generating
//* compliant IDs
// @param: inputString: string, the string to convert
// @param: revertRetroflexes: boolean, if true, replace retroflex characters
//         with their origin letter e.g. ḻ > l
export const replaceSpecialCharacters = (inputString, revertRetroflexes) => {
  const regex = /[^0-9a-z]/gi,
    retroflexOrigins = {
      l: 'ḻ',
      n: 'ṉ',
      r: 'ṟ',
      t: 'ṯ',
    };

  // If a character within the string is a retroflex, replace it with a
  // non-retroflex version to prevent misspelt words in the URL e.g. #ulu-u
  if (revertRetroflexes === true) {
    for (let key in retroflexOrigins) {
      inputString = inputString.replaceAll(retroflexOrigins[key], key);
    }
  }

  return inputString.replaceAll(regex, '-');
};

export const convertStringToId = (str) => {
  if (isInputDataFormatCorrect(str, 'string', 'convertStringToId()')) {
    // Check if the first character is a number, if so, prefix with 'id-' to make it valid
    str = !Number.isNaN(parseInt(str.split('')[0])) ? (str = 'id-' + str) : str;
    // Make it lowercase and replace all non A-Z characters with a dash
    str = replaceSpecialCharacters(str.toLowerCase(), true).replace(/-+/g, '-');
    // Cap the new ID length at 100 characters
    str = str.length > 80 ? (str = str.substring(0, 80)) : str;
    return str;
  }
};

//* Strip Drupal's URL prefixes, if any
// Targets entity: and internal: while leaving valid links like http://, mailto:
// Relative internal links have a leading slash added e.g node/123 > /node/123
// Returns the URL, altered or not.
//   @param url: string, the URL to strip
export const stripUrlEntityPrefix = (url) => {
  if (url) {
    const parts = url.split(':'),
      prefix = parts[0],
      path = parts[1] || null;
    if (prefix.includes('internal') || prefix.includes('entity')) {
      // Don't add a leading slash to fragment links
      return path.startsWith('#')
        ? (url = url.replace(prefix + ':', ''))
        : (url = url.replace(prefix + ':', '/').replace('//', '/') || url);
    }
  }
  return url;
};

//* Strip park prefixes, if any. Returns the URL, altered or not
//   @param url: string, the URL to strip
export const stripUrlParkPrefix = (url) => {
  if (url && park) {
    if (isUriExternal(url)) {
      return url;
    }
    // Prefix the url with a slash if there isn't one already
    url = url.slice(0, 1) !== '/' ? `/${url}` : url;

    const prefix = url.split('/')[1] || '';

    if (prefix === park) {
      return url.replace('/' + prefix, '');
    } else if (prefix.includes('_app')) {
      console.warn(`[ISSUE - Links]: App content link found in menu: '${prefix}' in ${url}`);
    } else if (prefix !== park && parkList[prefix] !== undefined) {
      // This may be deliberate as some park menus include links to others e.g. Corporate site
      // console.warn(`[ISSUE - Links]: Menu link to another park found: '${prefix}' in ${url}`);
      return url.replace(prefix + '/', '');
    }
  }
  return url;
};

//* Test if a URI is external
//   @param uri: string, the URI to test
// NOTE: This doesn't check if the URL is internal but fully qualified e.g
// https://parksaustralia.gov.au/123 - these will be treated as external links
export const isUriExternal = (uri) => {
  try {
    let test = new URL(uri);
    return test !== undefined ? true : false;
  } catch (e) {
    return false;
  }
};

//* Test if URI points to a file with an extension
export const isUriDownloadableFile = (uri) => {
  const ext = uri.split(/[#?]/)[0].split('.').pop().trim();
  const validExt = () => ext.indexOf('/') === -1 && ext.indexOf('htm') === -1;
  return validExt();
};

//* Test if a string is a filepath - assumes file is under at least one
//* parent directory and has an extension
export const isFilePath = (string) => {
  try {
    if (!isInputDataFormatCorrect(string, 'string', 'isFilePath')) {
      return;
    }

    let hasLevels = string.includes('/'),
      fileExt = string.split('.')[string.split('.').length - 1],
      extHasSpaces = fileExt.includes(' ');
    if (fileExt && hasLevels && !extHasSpaces) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(`[ERROR - Links]: ${e}`);
  }
};

//* Convert true/false strings to boolean values
// Useful for boolean ENV vars that are pulled in as strings
// Returns false by default
export const convertTrueFalseStringToBoolean = (str) => str === 'true';

//* Test the current environment for the host, branch, content source etc
export const testEnv = () => {
  let env = {};
  env.branch = process.env.BRANCH || false;
  env.drupalSource = process.env.GATSBY_DRUPAL_DOMAIN || false;
  env.devMode = process.env.GATSBY_DEV_MODE
    ? convertTrueFalseStringToBoolean(process.env.GATSBY_DEV_MODE)
    : false;
  return env;
};

//* Check if something is test/dummy content
// Dummy content is present in Drupal only to prevent Gatsby builds failing and
// should never be live. Test content can be published in dev environments for
// testing purposes only. @param targetField: string, the field to check
//  @param str: string, the string to check. Case sensitive
export const isDummyOrTestContent = (str) => {
  const strPrefix = ['DUMMY', 'TEST'];
  if (str && typeof str === 'string') {
    return strPrefix.some((prefix) => str.startsWith(prefix)) ? true : false;
  }
  return false;
};

//* Convert bytes to human-readable filesize e.g. 2.4Mb
export const convertBytesToHumanReadableFileSize = (bytes) => {
  bytes = parseInt(bytes) || null;
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
};

//* Extract the file extension from a string - can be a MIMETYPE or filename
export const getFileExtension = (fileName) => {
  if (!fileName || fileName.split('.') === -1) return false;
  const extension = fileName.split('.')[fileName.split('.').length - 1];
  return extension.toUpperCase();
};

//* Round up or down a non-Integer number to the nearest Integer
export const roundNumberUpOrDown = (num) => {
  if (typeof num !== 'number') {
    console.error('[ERROR - Scripts]: roundNumberUpOrDown() requires a number');
    return num;
  }
  return !Number.isInteger(num) ? (num % 1 > 0.5 ? Math.ceil(num) : Math.floor(num)) : num;
};

//* Format a series of numbers into human-readable format
// e.g. 1234567 becomes 1,234,567
export const formatNumber = (num) => {
  if (!num) {
    console.warn('[ISSUE - Scripts]: No number provided to formatNumber()');
    return;
  }
  return parseInt(num).toLocaleString();
};

//* Validate if a string is a valid Australian phone number
// Assumes no whitespace between numbers
export const isValidAustPhoneNumber = (phoneNumber) => {
  // Rather than some convoluted regex that tries to match all possible phone number formats,
  // break it into several tests and return true if any of them match
  const numLandlinesAndMobiles = /^(?:\+?61|0)[2-9]\d{8}$/,
    numHotlines = /^(?:1300|1800)\d{6}$/;
  let result =
    numLandlinesAndMobiles.test(phoneNumber) || numHotlines.test(phoneNumber) ? true : false;
  return result;
};

//* Lowercase first character of a string, return the new string
export const lowercaseFirstCharacter = (str) => {
  isInputDataFormatCorrect(str, 'string', 'lowercaseFirstCharacter()');
  return str.charAt(0).toLowerCase() + str.slice(1);
};

//* Uppercase first character of a string, return the new string
export const uppercaseFirstCharacter = (str) => {
  isInputDataFormatCorrect(str, 'string', 'uppercaseFirstCharacter()');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//* Remap a object of list data to expose a new value, as Drupal's JSON API
//* doesn't expose the labels of plain text lists :(
//    data: an array containing the list of values to remap
//    newObj: an object containing the new values to map to
//    boolIncludeEmpty: if true, include values missing from 'data'
//       but present in 'newObj' in the remapped array
export const remapDrupalListKeysToLabels = (data, newObj, boolIncludeEmpty) => {
  if (
    isInputDataFormatCorrect(newObj, 'object', 'remapDrupalListKeysToLabels()') &&
    isInputDataFormatCorrect(boolIncludeEmpty, 'boolean', 'remapDrupalListKeysToLabels()')
  ) {
    let newArr = [];

    if (boolIncludeEmpty) {
      for (const key in newObj) {
        !data.includes(key)
          ? // Send back an object containing the keys you may then use in featureList()'s args
            newArr.push({ item: newObj[key], enabled: false })
          : newArr.push({ item: newObj[key], enabled: true });
      }
    } else {
      data.map((item) => {
        newObj[item] ? newArr.push({ item: newObj[item] }) : newArr.push({ item: item });
        return null; // this is just here to stop the linter complaining
      });
    }
    return newArr;
  }
};

//* Get Park details
// Returns an object containing the various names of the current park,
// e.g.shortName, fullName, domain
export const getParkNames = () => {
  return parkList[park] ? parkList[park] : null;
};

//* Get all Park's details
// Returns an object containing the various names of the current park,
// e.g.shortName, fullName, domain
export const getAllParkNames = () => {
  return parkList;
};

//* Generate a random numeric key every time
// Don't use this is you need a key to remain consistent e.g. stateful components in React,
//  as it will be regenerated different every time
export const randomKey = () => {
  return Math.floor(Math.random() * 1000000000);
};

//* Test if a URL is absolute or relative, and make domains consistent
//    url: string, the URL to test
//    useDomain: boolean, prefix the URL with the domain defined in GATSBY_DRUPAL_DOMAIN
export const prefixDomainToUrl = (url = '') => {
  let domain = process.env.GATSBY_DRUPAL_DOMAIN || null;

  if (!url || typeof url !== 'string') {
    console.warn('[ISSUE - Scripts]: prefixDomainToUrl() missing URL arg as a string');
    return;
  }
  if (!domain) {
    console.warn('[ISSUE - Scripts]: No domain defined in .env file');
    return url;
  }

  // If the URL already begins with the site domain, nothing to do
  if (url.slice(0, domain.length) === domain) {
    return url;
  } else {
    if (isUriExternal(url)) {
      // It's a genuinely external link, leave it as is
      return url;
    }
    // Prefix the domain
    return `${domain + url}`;
  }
};

//* Get the name of the image field to use for a given node type,
//* as different node types use different image fields
//!  If any additional fields are ever added to Drupal to capture Tile
//!  Images (which they shouldn't), they need to be added here
//   graphQLInternalType: string - the GraphQL node object to inspect
//   e.g.item.internal.type
export const getTileImageFieldName = (graphQLInternalType) => {
  if (!graphQLInternalType) {
    console.warn(
      `[ISSUE - Scripts]: getTileImageFieldName() requires a GraphQL node type, defaulting to 'field_tile_image'`
    );
  }
  switch (graphQLInternalType) {
    case 'node__person':
      return 'field_profile_image';
    case 'node__publication':
      return 'field_image';
    default:
      return 'field_tile_image';
  }
};

//* Standardize URLs to absolute format with no trailing slash or parameters/anchors
//  @param   url: string, the URL to clean
export const cleanUrlAbsolute = (url) => {
  // Remove any anchors/parameters
  url = url.split('?')[0];
  url = url.split('#')[0];
  // Enforce starting with a slash
  url = url.startsWith('/') ? url : `/${url}`;
  // Remove any trailing slashes (not the homepage) so we don't get an empty string
  url = url.endsWith('/') && url.length > 1 ? url.slice(0, -1) : url;

  return url;
};

//* Standardize URLs to absolute format but include a trailing slash
//  @param   url: string, the URL to clean
export const cleanUrlAbsoluteWithSlash = (url) => {
  // Remove any anchors/parameters
  url = url.split('?')[0];
  url = url.split('#')[0];
  // Enforce starting with a slash
  url = url.startsWith('/') ? url : `/${url}`;
  // Include a trailing slash if there isn't one already
  url = url.endsWith('/') ? url : `${url}/`;

  return url;
};

//* Test a URL to see if it matches a URL pattern
// Returns true if the URL and pattern are identical, or if the url matches
// the URL pattern e.g. /about/* matches /about/our-team and /about/some/place
// It does no cleanup of URLs for consistency, so /about/ and /about are not the same
//    urlPattern: string, the URL pattern to match against
//    url: string, the URL to test
// TODO: Check if there's a lib that handles all cases e.g.
// https://developer.mozilla.org/en-US/docs/Web/API/URL_Pattern_API
// https://www.npmjs.com/package/url-pattern
// TODO: If not, update this to handle extended rules
// TODO: e.g. / about/**/sub - sub - page, /prefix-*/*/*-suffix
export const urlPatternMatch = (urlPattern, url) => {
  if (urlPattern || typeof url === 'string' || typeof urlPattern === 'string') {
    return url === urlPattern
      ? true
      : urlPattern.includes('/*') === true && url.startsWith(urlPattern.split('*')[0])
      ? true
      : false;
  } else {
    console.warn(`[ISSUE - Scripts]: urlPatternMatch() expects a string for both args!`);
    return false;
  }
};

//* Test if a date is in the past
//  @param: dateStr: string, the date string. Accepts ISO 8601 date strings
//  Gatsby uses moment.js for date handling. We convert to a unix timestamp to
//  numerically test the dates, to avoid the hassle of wrangling timestamps and
//  not knowing where in the world the server may execute to get the current time.
export const hasDatePassed = (dateStr) => {
  const dateISO = moment(dateStr, moment.ISO_8601);
  if (!dateISO.isValid()) {
    console.warn(`[ISSUE - Scripts]: hasDatePassed() expects a valid ISO 8601 date string`);
    return null;
  }
  const date = moment(dateStr).unix(),
    now = moment().unix();
  return date < now ? true : false;
};

//* Format a date and apply the AEST timezone
// @param dateStr: string, the date string to format. Accepts ISO 8601 date strings
// @param dateFormat: string, the output format to apply to the date
// @return dateStr as a string formatted in AEST as 'dddd D MMMM YYYY' or null if invalid
export const formatDateToAEST = (dateStr, dateFormat = 'dddd D MMMM YYYY') => {
  // Catch booleans as these fail in Moment but return the Epoch date in the
  // Date() constructor
  if (typeof dateStr === 'boolean') {
    console.warn(
      `[ISSUE - Scripts]: formatDateToAEST() expects a valid date string, '${dateStr}' received`
    );
    return null;
  }

  const dateAsMoment = (date) => moment(date, moment.ISO_8601),
    dateIsValidMoment = (date) => date.isValid();
  let dateInit = dateAsMoment(dateStr),
    // Fall back to vanilla JS Date() if Moment fails to parse
    date = dateIsValidMoment(dateInit) ? dateInit : dateAsMoment(new Date(dateStr).toISOString());

  // Test if date is valid again in case Date() fails to parse
  return dateIsValidMoment(date) ? moment(date).tz('Australia/Sydney').format(dateFormat) : null;
};

//* Test if the URL of a page matches a given pattern
// @param   urlPattern: array, an array of URL patterns to match against
// @param   includeUrls: boolean, if true, show on the URLs in the array
// @param   pagePath: string, the current page's URL
export const currentPageMatchesUrlPattern = (
  urlPattern = [],
  includeUrls = true,
  pagePath = ''
) => {
  const sanitizedUrlPatterns = urlPattern?.map((url = String, index) => {
    return !url ? null : cleanUrlAbsoluteWithSlash(url);
  });
  let match = false;

  // If there are no URLs to match against and includeUrls is false,
  // let it pass as it's a 'show on all' rule
  if (typeof urlPattern === 'object' && urlPattern.length === 0 && !includeUrls) {
    match = true;
    // Otherwise, check if the URL matches the pattern
  } else if (sanitizedUrlPatterns !== null && sanitizedUrlPatterns.length > 0) {
    // Invert the logic for 'show on ...' matches vs 'show on all EXCEPT...' matches
    if (includeUrls === true) {
      for (let pattern in sanitizedUrlPatterns) {
        match = urlPatternMatch(sanitizedUrlPatterns[pattern], pagePath) === true ? true : false;
        if (match === true) break;
      }
    } else {
      for (let pattern in sanitizedUrlPatterns) {
        match = urlPatternMatch(sanitizedUrlPatterns[pattern], pagePath) === true ? false : true;
        if (match === false) break;
      }
    }
  }
  return match;
};

//* Check if an array contains multiple items, to treat as a list
// @param arr: array, the array to check
// @return {boolean} true if arr is an Array and contains > 1 item
export const isArrayAList = (arr) => Array.isArray(arr) && arr.length > 1;

//* Ensure lat/lon coordinates are valid
// @param Array: an array containing the lat/lon coordinates respectively
// @return Array: the corrected (if required) lat/lon coordinates
export const validateCoordinates = (Array) => {
  let lat = parseFloat(Array[0]);
  lat = isNaN(lat) || lat < -90 ? -28.2744 : lat > 90 ? -28.2744 : lat;

  let lon = parseFloat(Array[1]);
  lon = isNaN(lon) || lon < -180 ? 134.7751 : lon > 180 ? 134.7751 : lon;

  return [lat, lon];
}
