import React from 'react';
import { createMenuTree } from './build-menu-tree';
import Link from '../../components/elements/link';
import { useStaticQuery, graphql } from 'gatsby';

const park = process.env.GATSBY_PARK;
const menuId = park + '-footer-nav';
const menuMaxLevel = 2;

const buildLink = (menuItem) => (
  <Link to={menuItem.link.uri_alias || menuItem.link.uri}>{menuItem.title}</Link>
);

const buildMenu = (menuArray) => {
  if (!menuArray) {
    return;
  }

  let menu = [];

  for (let item in menuArray) {
    let menuItem = menuArray[item];

    if (menuItem.menu_level > menuMaxLevel || menuItem.enabled !== true) {
      continue;
    }

    // For Top level menu items
    if (menuItem.menu_level === 1) {
      // If the Top level menu item has any children
      // This should be updated to nest all menu items in a single menu, but it
      // breaks styles. See PADM-719
      if (menuItem.children.length !== 0) {
        menu.push(
          <ul className="footer__nav-list " key={menuItem.drupal_id}>
            <li className="footer__nav-list-item footer__nav-list-item--heading">
              {buildLink(menuItem)}
            </li>
            {buildMenu(menuItem.children)}
          </ul>
        );
      } else {
        // If this Top level menu item has no children
        menu.push(
          <div className="footer__nav-list" key={menuItem.drupal_id}>
            <span className="footer__nav-list-item footer__nav-list-item--heading">
              {buildLink(menuItem)}
            </span>
          </div>
        );
      }
    } else {
      // For all other menu items
      menu.push(
        <li className="footer__nav-list-item" key={menuItem.drupal_id}>
          {buildLink(menuItem)}
        </li>
      );
    }
  }
  return menu;
};

const generateMenu = (menuLinks) => {
  let menu = createMenuTree(menuLinks.allMenuItem.edges, menuId);
  menu = buildMenu(menu);

  return menu && menu.length !== 0 ? menu : [];
};

export default function FooterMenu() {
  // This query deliberately returns all items for all menus,
  // which are then filtered above. We can't dynamically filter
  // by park or menu name as static queries don't accept variables.
  // Filtering is done in createMenuTree();
  const data = useStaticQuery(graphql`
    {
      allMenuItem(sort: { fields: weight }) {
        edges {
          node {
            enabled
            title
            menu_name
            link {
              uri
              uri_alias
            }
            description
            drupal_id
            bundle
            drupal_parent_menu_item
            weight
            external
          }
        }
      }
    }
  `);
  return data ? generateMenu(data) : <></>;
}
