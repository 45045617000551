import React, { memo } from 'react';
import SharedParksLogoBlock from '../blocks/shared-parks-logo';

const FooterSharedParksLogoPanel = memo(() => {
  return (
    <div className="footer__park-logos-panel">
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <SharedParksLogoBlock />
        </div>
      </div>
    </div>
  );
});

export default FooterSharedParksLogoPanel;
