import React from "react";
import PageHeader from "../components/structure/header";
import FooterLogosPanel from "../components/structure/footer-logos-panel";
import FooterNavigationPanel from "../components/structure/footer-navigation-panel";
import FooterSharedParksLogoPanel from "../components/structure/footer-parks-logo-panel";

export default function Layout({ children }) {
  return (
    <>
      <div className="masthead">
        <div className="masthead__inner clearfix">
          <PageHeader />
        </div>
      </div>
      <div className="main-content" id="main-content" role="main">
        {children}
      </div>
      <div className="footer" role="contentinfo">
        <FooterLogosPanel />
        <FooterNavigationPanel />
        <FooterSharedParksLogoPanel />
      </div>
    </>
  );
}
